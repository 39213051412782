import React, { useState } from 'react'

export const locations = [
    {
        id: 'blr',
        country: 'India',
        city: 'Bengaluru',
        address: '7th Floor, Building No. 32,RMZ Ecoworld, Devarabisanahalli, Bengaluru, Karnataka 560103',
        phone: '+91 82170 83171',
        email: 'support@spiolabs.com',
        iframeUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.515666161905!2d77.6878095751526!3d12.938820715595932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1333be18b0bf%3A0xd37dbcee900e1701!2sAstria%20Digital%20Private%20Limited!5e0!3m2!1sen!2sin!4v1734534352237!5m2!1sen!2sin'
    },
    {
        id: 'hyd',
        country: 'India',
        city: 'Hyderabad',
        address: '102, Hemalatha apts, Gagan Mahal Road, Domalguda, Hyderabad, Telangana, 500029',
        phone: '+91 92170 83171',
        email: 'support@spiolabs.com',
        iframeUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7614.051285533472!2d78.46881279357912!3d17.410557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99e3d279b037%3A0xba11e4fc01e6fc0!2sHemalatha%20apartments!5e0!3m2!1sen!2sin!4v1734534296732!5m2!1sen!2sin'
    },
    {
        id: 'usa',
        country: 'USA',
        address: '16192 Coastal Highway, Lewes, Delaware - 19958',
        phone: '+91 92170 83171',
        email: 'support@spiolabs.com',
        iframeUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.936642621714!2d-75.21428062411591!3d38.76515715449616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8b9ee12ce8257%3A0x3122963d2d6d3bc5!2s16192%20Coastal%20Hwy%2C%20Lewes%2C%20DE%2019958%2C%20USA!5e0!3m2!1sen!2sin!4v1736393238715!5m2!1sen!2sin'

    },
    {
        id: 'ire',
        country: 'Ireland',
        address: 'Unit F 13, Maynooth Business Campus, Maynooth Co. Kildare. W23 Y5N7',
        phone: '+353 89 234 4011',
        email: 'support@spiolabs.com',
        iframeUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9523.088532198946!2d-6.596827226200738!3d53.36523286081638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48677015d266f207%3A0xa4aade0584cd83f6!2sMoneycooly%2C%20Co.%20Kildare%2C%20W23%20Y5N7%2C%20Ireland!5e0!3m2!1sen!2sin!4v1736393400149!5m2!1sen!2sin'

    }
    ,
    {
        id: 'ksa',
        country: 'KSA',
        address: '1st Floor,Building No 7777, Sahab Business Center, Al-Rakha Ash Shamaliya, King Fahd Road, Al Khobar - 34225, Saudi Arabia',
        phone: '+966 13 868 8337',
        email: 'support@spiolabs.com',
        iframeUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.8487013331546!2d50.18363947543379!3d26.363755183466253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49ef0f2c91edef%3A0x219aca47ac35201e!2sSahab%20Business%20Center!5e0!3m2!1sen!2sin!4v1736393567137!5m2!1sen!2sin'
    }
];
export default function Location() {
    const [activeLocation, setActiveLocation] = useState(locations[0])
    const [openAccordion, setOpenAccordion] = useState(null);
    const toggleAccordion = (locationId) => {
        setOpenAccordion(openAccordion === locationId ? null : locationId);
    };

    return (
        <div className='bg-gray-100 py-4 md:py-12'>
            <div className="mx-auto py-8 px-4 sm:px-6 lg:px-10">
                <h1 className="text-3xl md:text-5xl font-bold pb-6">Our Locations</h1>
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="space-y-6">
                        {Object.entries(
                            locations.reduce((acc, location) => {
                                if (!acc[location.country]) {
                                    acc[location.country] = []
                                }
                                acc[location.country].push(location)
                                return acc
                            }, {})
                        ).map(([country, locations], index, array) => (
                            <div key={country}>
                                <h2 className="font-bold text-lg md:text-2xl mb-2 pl-2 md:pl-4">{country}</h2>
                                <div className="space-y-6">
                                    {locations.map((location) => (
                                        <button
                                            key={location.id}
                                            onClick={() => {
                                                setActiveLocation(location)
                                                toggleAccordion(location.id)
                                            }}
                                            className={`text-left cursor-pointer duration-150 transition-all px-2 md:px-4 ${activeLocation.id === location.id
                                                ? 'md:border-l-4 md:border-l-[#F30002]'
                                                : 'md:hover:border-l-4 md:border-l-gray-500'
                                                }`}
                                        >
                                            {location.city && (
                                                <h3 className="font-semibold pb-2 text-sm md:text-base">{location.city}</h3>
                                            )}
                                            <p className="text-gray-600 mb-2">{location.address}</p>
                                            <div className="text-base text-gray-600 mt-4 flex flex-col md:flex-row gap-3 md:gap-x-12">
                                                <a href={`tel:${location.phone}`} className="hover:text-primary"
                                                    onClick={(e) => e.stopPropagation()}>{location.phone}</a>
                                                <a
                                                    href={`mailto:${location.email}`}
                                                    className="hover:text-primary"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    {location.email}
                                                </a>
                                            </div>
                                            {/* Accordion for Map (Mobile) */}
                                            {openAccordion === location.id && (
                                                <div className="mt-4 block md:hidden">
                                                    <Map location={location} />
                                                </div>
                                            )}
                                        </button>

                                    ))}
                                    {index < array.length - 1 && <hr className="mt-6 border-t border-gray-300" />}
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className="hidden md:flex items-center justify-center shadow-xl">
                        <Map location={activeLocation} />
                    </div>
                </div>
            </div>
        </div >
    )
}
function Map({ location }) {
    return (
        <div className="bg-gray-100 rounded-lg w-full h-[280px] md:h-full overflow-hidden shadow">
            <iframe
                title={location.id}
                src={location.iframeUrl}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="h-full w-full"
            />
        </div>
    );
}

